@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  font-family: "Poppins";
}
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  background-color: #f5f5f5;
  margin: 0;
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes pulse {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes jump {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px); /* Adjust the jump height as needed */
  }
}

.searching-text {
  font-size: 1.5rem;
  text-align: center;
  animation: pulse 2s infinite;
}

.dot {
  display: inline-block;
  animation: jump 2s infinite; /* Slowed down to 2 seconds */
}

.dot:nth-child(2) {
  animation-delay: 0.5s; /* Adjusted delay to match the slower animation */
}

.dot:nth-child(3) {
  animation-delay: 1s; /* Adjusted delay to match the slower animation */
}
